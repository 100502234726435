import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getUsersDetail,
  getApplicationDetail,
  getUserDetail,
} from "../../services/dashboard";
import { GoBell } from "react-icons/go";
import logo from "../../images/logoSidebar.jpg";
import icon1 from "../../images/home.jpg";
import icon2 from "../../images/user.jpg";
import icon3 from "../../images/file.jpg";
import icon4 from "../../images/card.jpg";
import img from "../../images/admin.jpg";
import img1 from "../../images/ellips4.jpg";
import img2 from "../../images/whatsapp-icon.jpg";
import img3 from "../../images/gmail.jpg";
import img4 from "../../images/word.jpg";
import img5 from "../../images/pdf.jpg";
import { useFirebase } from "../../context/context";
import emailjs from "@emailjs/browser";
import CustomModal from "../../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Image from "../../images/Dhani New logo.png";
import SignatureLogo from "../../images/signatureLogo.png";
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState("home");
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const [searchparams] = useSearchParams();
  const whatsappNumber = searchparams.get("whatsappNumber");
  const [show, setShow] = useState(false);
  const [showAttach, setShowAttach] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
    useEffect(() => {
      if (location.state && location.state.activeMenu) {
        setActiveMenu(location.state.activeMenu);
      }
  
    }, [location.state]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const handleRowClick = (whatsappNumber) => {
    navigator.clipboard.writeText(whatsappNumber) // Copy the WhatsApp number to clipboard
      .then(() => {
        alert(`WhatsApp number ${whatsappNumber} copied to clipboard`);
        navigate('/customer'); // Redirect after successful clipboard copy
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard:', error);
        alert('Failed to copy WhatsApp number to clipboard.');
      });
  };

  const handleLogout = () => {
    navigate('/adminLogin');
  };

  const firebase = useFirebase();

  useEffect(() => {
    if (activeMenu === "home") {
      getUserDetail(whatsappNumber)
        .then((res) => {
          console.log("ress.....", res);
          setSelectedUser(res);
          // getPost(res.id);
        })
        .catch((error) => {
          setSelectedUser({});
        });
    } else if (activeMenu === "users") {
      getUsersDetail()
        .then((res) => {
          setCurrentData(res);
        })
        .catch((error) => {
          setCurrentData({});
        });
    } else if (activeMenu === "applications") {
      getApplicationDetail()
        .then((res) => setCurrentData(res))
        .catch((error) => {
          setCurrentData({});
        });
    }
  }, [activeMenu]);

  useEffect(() => {
    const testCombineData = async () => {
      try {
        // Call combineData
        const data = await firebase.combineData();

        if (data === undefined) return;

        console.log("combineData executed successfully", data);

        // Retrieve and log the combined data
        const combinedData = await firebase.getCombinedData();
        console.log("Combined Data:", combinedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    testCombineData();
  }, [firebase]);

  const generatePDF = () => {
    // const input = document.getElementById("pdf-content");

    // html2canvas(input).then((canvas) => {
    //   const pdf = new jsPDF("p", "mm", "a4");
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdfWidth = 210; // A4 page size: 210mm x 297mm
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //   const marginLeft = 20; // Left margin in mm
    //   const marginRight = 20; // Right margin in mm

    //   pdf.addImage(
    //     imgData,
    //     "PNG",
    //     marginLeft,
    //     0,
    //     pdfWidth - marginLeft - marginRight,
    //     pdfHeight
    //   );

    const input = document.getElementById("pdf-content");

    html2canvas(input).then((canvas) => {
      const pdf = new jsPDF("p", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
      const pdfWidth = 210; // A4 page size: 210mm x 297mm
      let pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      const marginLeft = 20; // Left margin in mm
      const marginRight = 20; // Right margin in mm
      const pageHeight = pdf.internal.pageSize.height;

      let position = 0;
      let remainingHeight = pdfHeight;

      while (remainingHeight > 0) {
        pdf.addImage(
          imgData,
          "PNG",
          marginLeft,
          position,
          pdfWidth - marginLeft - marginRight,
          remainingHeight >= pageHeight ? pageHeight : remainingHeight
        );

        remainingHeight -= pageHeight - 10; // Some margin between pages
        position -= pageHeight - 10; // Some margin between pages

        if (remainingHeight > 0) {
          pdf.addPage();
        }
      }
      pdf.save(`${selectedUser?.userDetail?.name}.pdf`);

      // setShowAttach(true);
    });
  };

  const handleOpenAttach = (value) => {
    let data = {};

    if (value === "approval_letter") {
      data = {
        email: selectedUser?.userDetail?.email,
        approval_letter: "Dynamic content",
      };
    } else {
      data = {
        email: selectedUser?.userDetail?.email,
        name: selectedUser?.userDetail?.name,
        pdf_file:
          "https://drive.google.com/file/d/1qOJyZn4R0Y_8VdrTyexB9uyQfCvnUtT0/view",
        message:
          "I've attached the agreement letter in PDF format. Please review it carefully.",
      };
    }

    const formElement = document.createElement("form");

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        const inputField = document.createElement("input");
        inputField.type = "hidden";
        inputField.name = key;
        inputField.value = data[key];
        formElement.appendChild(inputField);
      }
    }

    document.body.appendChild(formElement);

    emailjs
      .sendForm(
        "service_voe3wmq",
        "template_4onzsob",
        formElement,
        "TblxlJHh9H4SijeDW"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text) {
            toast.success(
              `Woohoo, ${
                value === "approval_letter"
                  ? "Approval letter "
                  : "Agreement letter"
              } mail sent successfully!`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
          }
        },
        (error) => {
          console.log(error.text);
          toast.success("Check your connection!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      );
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="me-3">
        <div className="row">
          <div className="col-md-2">
            <div
              className="bg-blue px-2 py-4 d-flex flex-column justify-content-between"
              style={{ height: "180vh" }}
            >
              <div>
                <img
                  src={logo}
                  alt="logo"
                  onClick={() => handleMenuClick("home")}
                />
                <div
                  onClick={() => handleMenuClick("home")}
                  className={`d-flex align-items-center gap-3 my-3 p-2 rounded cursor-pointer  ${
                    activeMenu === "home" ? "bg-orange" : ""
                  }`}
                >
                  <img src={icon1} alt="" />
                  <p
                    className={`mb-0 text-light ${
                      activeMenu === "home" ? "active" : ""
                    }`}
                  >
                    Home
                  </p>
                </div>
                <div
                  onClick={() => handleMenuClick("users")}
                  className={`d-flex align-items-center gap-3 my-3 p-2 rounded cursor-pointer  ${
                    activeMenu === "users" ? "bg-orange" : ""
                  }`}
                >
                  <img src={icon2} alt="" />
                  <p
                    className={`mb-0 text-light ${
                      activeMenu === "users" ? "active" : ""
                    }`}
                  >
                    Users
                  </p>
                </div>
                <div
                  onClick={() => handleMenuClick("applications")}
                  className={`d-flex align-items-center gap-3 my-3 p-2 rounded cursor-pointer  ${
                    activeMenu === "applications" ? "bg-orange" : ""
                  }`}
                >
                  <img src={icon3} alt="" />
                  <p
                    className={`mb-0 text-light ${
                      activeMenu === "applications" ? "active" : ""
                    }`}
                  >
                    Applications
                  </p>
                </div>
                <div
                  onClick={() => handleMenuClick("pay")}
                  className={`d-flex align-items-center gap-3 my-3 p-2 rounded cursor-pointer  ${
                    activeMenu === "pay" ? "bg-orange" : ""
                  }`}
                >
                  <img src={icon4} alt="" />
                  <p
                    className={`mb-0 text-light ${
                      activeMenu === "pay" ? "active" : ""
                    }`}
                  >
                    Pay
                  </p>
                </div>
              </div>
              <div className="bg-orange d-flex flex-column gap-2 py-4 align-items-center justift-content-center rounded">
                <div>
                  <img src={img1} alt="" />
                </div>
                <div>
                  <p className="mb-0 text-light text-center">Admin</p>
                  <p className="mb-0 text-light text-center">
                    admin@email.com
                  </p>
                </div>
                <div>
                  <button className="btn w-100 d-flex justift-content-center text-light bg-blue" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ........home..... */}
          {activeMenu === "home" && (
            <div className="row col-md-10">
              <div className="d-flex justify-content-between align-items-center py-2 ">
                <h5 className="fw-bold">Home</h5>
                <div className="d-flex align-items-center">
                  <div>
                    <button type="button" class="btn p-0 position-relative">
                      <GoBell size={24} />
                      <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                        <span class="visually-hidden">New alerts</span>
                      </span>
                    </button>
                  </div>
                  <div className="d-flex align-items-center gap-2 ms-5">
                    <img src={img} className="rounded-pill" alt="" />
                    <div className="">
                      <p className="mb-0 lead fw-500">Admin</p>
                      <p className="lead text-secondary mb-0">Student</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 my-3">
                <div className="shadow p-3">
                  <form>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label class="form-label">Full Name</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          value={selectedUser?.userDetail?.name}
                          aria-label="name"
                        />
                      </div>
                      <div className="col-md-6">
                        <label class="form-label">Mobile Number</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          value={selectedUser?.userDetail?.phoneNumber}
                          aria-label="Email Address"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label class="form-label">Loan Type</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          value={selectedUser?.loanDetail?.typeOfLoan}
                          aria-label="Phone Number"
                        />
                      </div>
                      <div className="col-md-6">
                        <label class="form-label">Date</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          placeholder="12 Aug, 2023"
                          aria-label="City"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label class="form-label">WhatApp Number</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          value={selectedUser?.userDetail?.whatsappNumber}
                          aria-label="name"
                        />
                      </div>
                      <div className="col-md-6">
                        <label class="form-label">Amount</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          value={selectedUser?.loanDetail?.loanAmount}
                          aria-label="Email Address"
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label class="form-label">Account No</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          value={selectedUser?.bankDetail?.bankAccountNumber}
                          aria-label="Phone Number"
                        />
                      </div>
                      <div className="col-md-6">
                        <label class="form-label">Bank IFC</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          aria-label="City"
                          value={selectedUser?.bankDetail?.ifscCode}
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label class="form-label">Bank Name</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          aria-label="name"
                          value={selectedUser?.bankAccountName}
                        />
                      </div>
                      <div className="col-md-6">
                        <label class="form-label">Email Address</label>
                        <input
                          type="text"
                          class="form-control shadow-none bg-light"
                          style={{ padding: "11px" }}
                          aria-label="Email Address"
                          value={selectedUser?.userDetail?.email}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="bg-blue p-3 row mx-1 justify-content-center gap-3 rounded">
                    <div className="col-sm-4">
                      <p className="text-light">Approve Amount</p>
                      <div className="bg-light text-center py-3 rounded">
                        {selectedUser?.loanDetail?.totalInterestAmount}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <p className="text-light">EMI</p>
                      <div className="bg-light text-center py-3 rounded">
                        {selectedUser?.loanDetail?.emi}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <p className="text-light">Tenure</p>
                      <div className="bg-light text-center py-3 rounded">
                        {selectedUser?.loanDetail?.loanTenure}
                      </div>
                    </div>
                  </div>

                  {/* ..........New Button............. */}
                  <div className="d-flex  gap-2 ms-2">
                    <div className="d-flex flex-column gap-2 ">
                      <button
                        className="bg-orange btn fs-8 custom-button text-light width102"
                        onClick={
                          () => generatePDF()
                          //  handleOpenAttach("approval_letter")
                        }
                      >
                        Approval <br />
                        Letter Mail
                      </button>
                      <button className=" fs-8 border-button text-dark width102">
                        Download <br /> Approval Letter{" "}
                      </button>
                      <button className=" fs-8 border-button text-dark width102">
                        Copy <br /> Letter Link{" "}
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <button className="bg-orange btn fs-8 custom-button text-light width102">
                        File Change <br /> Recived{" "}
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <button
                        className="bg-orange btn fs-8 custom-button text-light width102"
                        onClick={() => handleOpenAttach("agreement_letter")}
                      >
                        Agreement <br />
                        Letter Mail
                      </button>
                      <button className=" fs-8 border-button text-dark width102">
                        Download <br /> Approval Letter{" "}
                      </button>
                      <button className=" fs-8 border-button text-dark width102">
                        Copy <br /> Letter Link{" "}
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <button className="bg-orange btn fs-8 custom-button text-light width102">
                        Agreement <br />
                        Payment Received
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <button className="bg-orange btn fs-8 custom-button text-light width102">
                        Send Beneficiary <br /> Message
                      </button>
                      <button className="fs-8 border-button text-dark width102">
                        Transactional <br /> Message{" "}
                      </button>
                      <button className="fs-8 border-button text-dark width102">
                        Copy Transactional <br /> Message{" "}
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <button className="bg-orange btn fs-8 custom-button text-light width102">
                        Transactional <br /> Payment Recieved
                      </button>
                    </div>
                    <div className="d-flex flex-column gap-2 ">
                      <button className="bg-orange btn fs-8 custom-button text-light width102">
                        Insurance <br /> Payment Recieved
                      </button>
                    </div>
                  </div>
                  <div className="nav-item mt30">
                    <button className="bg-orange btn fs-10 custom-button text-light width172">
                      Cancelation
                    </button>
                    <button className="fs-10 border-button text-blue width172">
                      Copy Account Details
                    </button>
                    <button className="fs-10 border-button text-blue width172">
                      Copy FAQ
                    </button>
                    <button className="fs-10 border-button text-blue width172">
                      Copy Document Required
                    </button>
                  </div>
                  {/* ..........New Button............. */}
                </div>
              </div>
              <div className="col-md-3 my-3">
                <div className="shadow h-100 d-flex flex-column gap-3 py-3 px-3">
                  <button className="btn bg-orange text-light w-100 py-2">
                    Cancelation
                  </button>
                  <button
                    className="btn text-light w-100 py-2"
                    style={{
                      background:
                        "linear-gradient(rgba(224, 104, 77, 0.4),rgba(224, 104, 77, 0.4))",
                    }}
                  >
                    Copy Account Details
                  </button>
                  <button
                    className="btn text-light w-100 py-2"
                    style={{
                      background:
                        "linear-gradient(rgba(224, 104, 77, 0.4),rgba(224, 104, 77, 0.4))",
                    }}
                  >
                    Copy FAQ
                  </button>
                  <button
                    className="btn text-light w-100 py-2"
                    style={{
                      background:
                        "linear-gradient(rgba(224, 104, 77, 0.4),rgba(224, 104, 77, 0.4))",
                    }}
                  >
                    Copy Document Required{" "}
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* ....users... */}
          {activeMenu === "users" && (
            <div className="col-md-10 row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center py-2">
                  <h5 className="fw-bold">Users</h5>
                  <div className="d-flex align-items-center">
                    <div>
                      <button type="button" class="btn p-0 position-relative">
                        <GoBell size={24} />
                        <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                          <span class="visually-hidden">New alerts</span>
                        </span>
                      </button>
                    </div>
                    <div className="d-flex align-items-center gap-2 ms-5">
                      <img src={img} className="rounded-pill" alt="" />
                      <div className="">
                        <p className="mb-0 lead fw-500">James Williams</p>
                        <p className="lead text-secondary mb-0">Student</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="fs-14">
                          #
                        </th>
                        <th scope="col" className="fs-14">
                          Password
                        </th>
                        {/* <th scope="col" className="fs-14">
                          Active
                        </th> */}
                        {/* <th scope="col" className="fs-14">
                          Count
                        </th>
                        <th scope="col" className="fs-14">
                          Leads sent
                        </th> */}
                        <th scope="col" className="fs-14">
                          Name
                        </th>
                        <th scope="col" className="fs-14">
                          Mobile Number
                        </th>
                        <th scope="col" className="fs-14">
                          Email Address
                        </th>
                        <th scope="col" className="fs-14">
                          Bank Name
                        </th>
                        <th scope="col" className="fs-14">
                          Bank IFSC
                        </th>
                        <th scope="col" className="fs-14">
                          Account No
                        </th>
                        <th scope="col" className="fs-14">
                          File Charge
                        </th>
                        <th scope="col" className="fs-14">
                          Save
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData?.map((data, idx) => (
                        <tr key={idx}>
                          <th scope="row">{idx + 1}</th>
                          <td>
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              Send
                            </button>
                          </td>

                          <td>
                            {" "}
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              {data?.userDetail?.name}
                            </button>
                          </td>
                          <td>
                            {" "}
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              {data.userDetail?.phoneNumber}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              {data.userDetail?.email}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              {data?.bankDetail?.name}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              {data.bankDetail.ifscCode}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              {data.bankDetail.bankAccountNumber}
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn bg-light fs-13"
                              style={{
                                border: "1px solid rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              6678
                            </button>
                          </td>
                          <td>
                            <button className="btn bg-blue text-light fs-13">
                              Save
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {/* ........applications..... */}
          {activeMenu === "applications" && (
            <div className="col-md-10">
              <div className="d-flex justify-content-between align-items-center py-2">
                <h5 className="fw-bold">Applications</h5>
                <div className="d-flex align-items-center">
                  <div>
                    <button type="button" class="btn p-0 position-relative">
                      <GoBell size={24} />
                      <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                        <span class="visually-hidden">New alerts</span>
                      </span>
                    </button>
                  </div>
                  <div className="d-flex align-items-center gap-2 ms-5">
                    <img src={img} className="rounded-pill" alt="" />
                    <div className="">
                      <p className="mb-0 lead fw-500">James Williams</p>
                      <p className="lead text-secondary mb-0">Student</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" className="fs-14">
                          #
                        </th>
                        <th scope="col" className="fs-14">
                          Date
                        </th>
                        <th scope="col" className="fs-14">
                          Name
                        </th>
                        <th scope="col" className="fs-14">
                          Mobile
                        </th>
                        <th scope="col" className="fs-14">
                          Whatsapp
                        </th>
                        <th scope="col" className="fs-14">
                          Loan Type
                        </th>
                        <th scope="col" className="fs-14">
                          Loan Amount
                        </th>
                        <th scope="col" className="fs-14">
                          Reg Fee
                        </th>
                        <th scope="col" className="fs-14">
                          Reg Fee Status
                        </th>
                        <th scope="col" className="fs-14">
                          Approval Letter
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentData?.map((data, idx) => (
                        <tr key={idx} 
                        onClick={() => handleRowClick(data?.userDetail?.whatsappNumber)}
                        style={{ cursor: 'pointer' }}>
                          <th scope="row">{idx + 1}</th>
                          <td>12 Oct, 2023</td>
                          <td>{data?.userDetail?.name}</td>
                          <td>{data?.userDetail?.phoneNumber}</td>
                          <td>
                            <img src={img2} className="me-1" alt="" />
                            {data?.userDetail?.whatsappNumber}
                          </td>
                          <td>{data?.loanDetail?.typeOfLoan}</td>
                          <td className="text-danger">
                            ${data?.loanDetail?.loanAmount}
                          </td>
                          <td className="text-success">130.00</td>
                          <td>
                            <button className="btn inputDesign py-1">
                              UnPaid
                            </button>
                          </td>
                          <td className="d-flex gap-1">
                            <button className="inputDesign py-1 btn">
                              <img src={img3} alt="" />
                            </button>
                            <button
                              className=" py-1 btn border-primary"
                              style={{
                                background: " rgba(148, 224, 255, 0.53)",
                              }}
                            >
                              <img src={img4} alt="" />
                            </button>
                            <button className="inputDesign py-1 btn">
                              <img src={img5} alt="" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
        <CustomModal
          title={"Attach File"}
          body={
            <SendApprovalLetter
              setShowAttach={setShowAttach}
              setShow={setShow}
              email={selectedUser?.userDetail?.email}
              name={selectedUser?.userDetail?.name}
              message={
                "I've attached the Approval letter in PDF format. Please review it carefully."
              }
            />
          }
          show={showAttach}
          setShow={setShowAttach}
        />
        <CustomModal
          title={"Email Sent"}
          body={"Woohoo, email sent successfully!"}
          show={show}
          setShow={setShow}
        />
      </div>
      <div id="pdf-content" style={{ position: "absolute", bottom: "100" }}>
        <div
          id="pdf-content"
          style={{ width: "800px", margin: "auto", fontSize: "16px" }}
        >
          <div class="page" title="Page 1">
            <div class="layoutArea">
              <div class="column">
                <div class="page" title="Page 1">
                  <div class="layoutArea">
                    <div class="column">&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page" title="Page 1">
            <div class="layoutArea">
              <div class="column">
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>
                    <span
                      style={{
                        fontSize: "14pt",
                        fontFamily: "'DejaVuSerifCondensed', serif",
                      }}
                    >
                      LOAN APPROVAL LETTER
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="page" title="Page 1">
            <div class="layoutArea">
              <div class="column">
                <p>&nbsp;</p>
                <p
                  style={{
                    textAlign: "right",
                    position: "absolute",
                    left: "50%",
                  }}
                  align="right"
                >
                  <img
                    src={Image}
                    alt="image"
                    style={{
                      width: "400px",
                      height: "300px",
                    }}
                  />
                </p>
                <p>
                  <strong>
                    <span
                      style={{
                        fontSize: "14pt",
                        fontFamily: "'DejaVuSerifCondensed', serif",
                      }}
                    >
                      Dhani Finserv Limited&nbsp;
                    </span>
                  </strong>
                </p>

                <p>
                  <strong>
                    <span
                      style={{
                        fontSize: "14pt",
                        fontFamily: "'DejaVuSerifCondensed', serif",
                      }}
                    >
                      RBI : Active Approved Status&nbsp;
                    </span>
                  </strong>
                </p>

                <p>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "'DejaVuSerifCondensed', serif",
                    }}
                  >
                    415-416, ARUN CHAMBERS
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "'DejaVuSerifCondensed', serif",
                    }}
                  >
                    TARDEO ROAD MUMBAI
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "10pt",
                      fontFamily: "'DejaVuSerifCondensed', serif",
                    }}
                  >
                    MUMBAI MH INDIA 400034
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "9pt",
                      fontFamily: "'DejaVuSerifCondensed', serif",
                    }}
                  >
                    Mobile: +91 8018966740
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "9pt",
                      fontFamily: "'DejaVuSerifCondensed', serif",
                    }}
                  >
                    Email: loans@creditbro.co.in
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontSize: "9pt",
                      fontFamily: "'DejaVuSerifCondensed', serif",
                    }}
                  >
                    Web: https:/creditbro.co.in&nbsp;
                  </span>
                </p>
                <div class="page" title="Page 1">
                  <div class="layoutArea">
                    <div class="column">
                      <table
                        class="MsoNormalTable"
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          height: "151.5px",
                        }}
                        border="0"
                        width="100%"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr style={{ height: "151.5px" }}>
                            <td
                              style={{
                                width: "56.512%",
                                padding: "0cm 5.4pt",
                                height: "151.5px",
                              }}
                              valign="top"
                              width="56%"
                            >
                              <p class="MsoNormal">
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  To,
                                </span>
                              </p>
                              <p>
                                <strong>
                                  <span
                                    style={{
                                      fontSize: "9pt",
                                      fontFamily:
                                        "'DejaVuSerifCondensed', serif",
                                    }}
                                  >
                                    Battula santosh charan teja
                                  </span>
                                </strong>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  3-11-21/19 Dr Anangpall street Near pothuraju
                                  gudi 534350
                                </span>
                              </p>
                              <p>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  santoshcharanteja6@gmail.com&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                            </td>
                            <td
                              style={{
                                width: "43.488%",
                                padding: "0cm 5.4pt",
                                height: "151.5px",
                              }}
                              valign="top"
                              width="43%"
                            >
                              <p style={{ textAlign: "right" }} align="right">
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Document: SG/70407832
                                </span>
                              </p>
                              <p style={{ textAlign: "right" }} align="right">
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Document: SG/70407832
                                </span>
                              </p>
                              <p style={{ textAlign: "right" }} align="right">
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Proposal: SG/79818287
                                </span>
                              </p>
                              <p style={{ textAlign: "right" }} align="right">
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Dated: 27-10-2023
                                </span>
                              </p>
                              <p class="MsoNormal">&nbsp;</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p class="MsoNormal">&nbsp;</p>
                      <p>
                        <span
                          style={{
                            fontSize: "9pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          Dear,
                        </span>
                      </p>
                      <p>
                        <strong>
                          <span
                            style={{
                              fontSize: "9pt",
                              fontFamily: "'DejaVuSerifCondensed', serif",
                            }}
                          >
                            {selectedUser?.userDetail?.name}
                          </span>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          <span
                            style={{
                              fontSize: "9pt",
                              fontFamily: "'DejaVuSerifCondensed', serif",
                            }}
                          >
                            CreditBro Finance Pvt. Ltd. welcomes you. We are
                            Pleased to inform you that your application for
                            Personal Loan of amount &nbsp;
                            {selectedUser?.loanDetail?.totalInterestAmount} has
                            been accepted. The information mentioned by you has
                            been investigated secretly by the Company team
                            through online aadhaar/pan verification.Given below
                            are the details captured in the CreditBro Finance
                            Pvt. Ltd. Record. Please go through the details
                            carefully and intimate to us immediately in case of
                            any discrepancy. Your Application Details are given
                            below:&nbsp;
                          </span>
                        </strong>
                      </p>
                      <p class="MsoNormal" style={{ lineHeight: "150%" }}>
                        &nbsp;
                      </p>
                      <table
                        class="MsoTableGrid"
                        style={{
                          borderCollapse: "collapse",
                          border: "none",
                          width: " 99.1025%",
                        }}
                        border="1"
                        cellspacing="0"
                        cellpadding="0"
                      >
                        <tbody>
                          <tr
                            style={{ msoYftiIrow: "0", msoYftiFirstrow: "yes" }}
                          >
                            <td
                              style={{
                                width: "100%",
                                border: "1pt solid windowtext",
                                padding: " 0cm 5.4pt",
                              }}
                              colspan="2"
                              valign="top"
                              width="601"
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                  margin: "12pt 0cm 12pt 0cm",
                                }}
                                align="center"
                              >
                                <strong>
                                  <span
                                    style={{
                                      fontSize: "11pt",
                                      fontFamily:
                                        "'DejaVuSerifCondensed', serif",
                                    }}
                                  >
                                    APPLICATION DETAILS
                                  </span>
                                </strong>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 1 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Serial No.
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderTop: "none",
                                borderLeft: "none",
                                borderBottom: "1pt solid windowtext",
                                borderRight: "1pt solid windowtext",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  SG/86993
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 2 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Reference No.
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  SG/78222273
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 3 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Application No.
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  455560647
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 4 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Applicant Name
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.userDetail?.name}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 5 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Applicant Address
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.userDetail?.address}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 6 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  PAN Number
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.bankDetail?.panNumber ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 7 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Aadhaar Number
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.userDetail?.adharNumber ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 8 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Account Holder
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.userDetail?.name ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 9 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Account Number
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.bankDetail
                                    ?.bankAccountNumber ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 10 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  IFSC Code
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.bankDetail?.ifscCode ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 11 }}>
                            <td
                              style={{
                                width: "30.6028%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="217"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Bank Name
                                </span>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "69.3972%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              valign="top"
                              width="384"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  {selectedUser?.bankDetail?.name ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 12 }}>
                            <td
                              style={{
                                width: "100%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              colspan="2"
                              valign="top"
                              width="601"
                            >
                              <p
                                style={{
                                  textAlign: "right",
                                  position: "absolute",
                                  left: "50%",
                                }}
                                align="right"
                              >
                                <img
                                  src={SignatureLogo}
                                  alt="image"
                                  style={{
                                    width: "400px",
                                    height: "300px",
                                  }}
                                />
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                  margin: "12pt 0cm 12pt 0cm",
                                }}
                                align="center"
                              >
                                <strong>
                                  <span
                                    style={{
                                      fontSize: "11pt",
                                      fontFamily:
                                        "'DejaVuSerifCondensed', serif",
                                      color: "blue",
                                    }}
                                  >
                                    EMI and Loan Amount Approved
                                  </span>
                                </strong>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 13 }}>
                            <td
                              style={{
                                width: "100%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              colspan="2"
                              valign="top"
                              width="601"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  EMI : Rs{" "}
                                  {selectedUser?.loanDetail?.emi ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr style={{ msoYftiIrow: 14 }}>
                            <td
                              style={{
                                width: "100%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              colspan="2"
                              valign="top"
                              width="601"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Loan Amount : Rs{" "}
                                  {selectedUser?.loanDetail?.loanAmount ?? "-"}
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr
                            style={{ msoYftiIrow: "15", msoYftiLastrow: "yes" }}
                          >
                            <td
                              style={{
                                width: "100%",
                                borderRight: "1pt solid windowtext",
                                borderBottom: "1pt solid windowtext",
                                borderLeft: "1pt solid windowtext",
                                borderImage: "initial",
                                borderTop: "none",
                                padding: "0cm 5.4pt",
                              }}
                              colspan="2"
                              valign="top"
                              width="601"
                            >
                              <p style={{ margin: "6pt 0cm 6pt 0cm" }}>
                                <span
                                  style={{
                                    fontSize: "9pt",
                                    fontFamily: "'DejaVuSerifCondensed', serif",
                                  }}
                                >
                                  Interest Rate :{" "}
                                  {
                                    selectedUser?.loanDetail
                                      ?.totalInterestAmount
                                  }
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        <strong>
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "'DejaVuSerifCondensed', serif",
                            }}
                          >
                            Kindly submit complete documents as mentioned below:
                          </span>
                        </strong>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "9pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          1. Self attested copy of Aadhaar Card
                          <br />
                          2. Self attested copy of Pan Card
                          <br />
                          3. Self attested passport size photograph (two)
                          <br />
                          4. Copy of bank statement /Cancelled cheque /bank
                          passbook copy
                          <br />
                          5. Two references from your locality (having good
                          goodwill in the society) with full details including
                          contact numbers 6. File Charge amount- 1,299/- which
                          is refundable.
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "9pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          Note-File charge fee is completely refundable within
                          15 days.
                          <br />
                          <br />
                          Account Details:
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "9pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          Account Holder Name: {selectedUser?.userDetail?.name}
                          <br />
                          Account Number:{" "}
                          {selectedUser?.bankDetail?.bankAccountNumber}
                          <br />
                          Account Type: {selectedUser?.bankDetail?.accountType}
                          <br />
                          IFSC: {selectedUser?.bankDetail?.ifscCode}
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "9pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          Bank Name: {selectedUser?.bankDetail?.bankName}
                          <br />
                          Bank Address: {selectedUser?.bankDetail?.bankAddress}
                          <br />
                          Payment Mode: You can make payment through
                          NEFT/RTGS/IMPS/UPI/Net Banking. Note: Cash Deposit is
                          not allowed as per company's policy
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "11pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          Note: Kindly Pay File Charge Rs 1,299 today. This is a
                          Computer Generated Document, it Doesn't Require
                        </span>
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "11pt",
                            fontFamily: "'DejaVuSerifCondensed', serif",
                          }}
                        >
                          Signature&nbsp;
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

const SendApprovalLetter = (props) => {
  const { setShowAttach, setShow, email, name, message } = props;
  const [loading, setLoading] = useState(false);
  const form = useRef();

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    emailjs
      .sendForm(
        "service_voe3wmq",
        "template_4onzsob",
        form.current,
        "TblxlJHh9H4SijeDW"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text) {
            setShowAttach(false);
            setShow(true);
            toast.success(`Woohoo, Approval letter mail sent successfully!`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          toast.success("Check your connection!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      );
  };
  return (
    <form ref={form} onSubmit={handleSubmit}>
      <input
        type="name"
        name="name"
        value={name}
        style={{ marginBottom: 20 }}
      />
      <input
        type="email"
        name="email"
        value={email}
        style={{ marginBottom: 20 }}
      />
      <input
        type="message"
        name="message"
        value={message}
        style={{ marginBottom: 20 }}
      />
      <input type="file" name="approval_letter" style={{ marginBottom: 20 }} />
      <button
        disabled={loading ? true : false}
        type="submit"
        className="btn bg-orange py-2 text-light px-4"
      >
        {loading ? "loading..." : "Upload"}
      </button>
    </form>
  );
};
