import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <button className="fw-bold my-4 mx-auto 
        container d-flex justify-content-center btn bg-orange text-light col-md-4">
          Privacy Policy
        </button>
        <p>
          Megafin promises to protect the information that is provided to us
          by our users. The information may include any personal and financial
          information submitted by our users. We understand that while the user
          avails of any of our services, he/she is trusting us with their
          information. We, therefore, take it as our responsibility to protect
          any sensitive information related to our users. This true and is
          applicable to any individual who wants to avail of the services
          provided by our website. The terms and conditions of Website Use as
          mentioned in ‘Policy on Website Use of Megafin’ as amended from time
          to time are incorporated herein by way of reference.
        </p>
        <h5 className="fw-bold">Information We Collect</h5>
        <p className="my-3">
          The Customer provides his/ her personal information to Megafin,
          which may be of a confidential or sensitive nature, in the course of:
        </p>
        <p className="mb-0">
          • Availing various loans and services from or through Megafin,
          individually or together with third party service provider or business
          partners viz. banks and financial institutions,
        </p>
        <p className="mb-0">
          • Interactions with Megafin or its authorized representatives in
          respect of such loans and services, while using this Website, or
        </p>
        <p className="mb-0">
          • At the time of client acceptance/ registration/ application through
          any of the sources mentioned herein in connection with availing
          products or services of Megafin.
        </p>
        <p className="mb-0">
          Such information may include your personal details (like name,
          address, date of birth, father’s name, mother’s name, etc.), contact
          details (like your residential address, email address, telephone/
          mobile number etc.), business, credit and financial information,
          device location and information, messages, contacts, call logs,
          emails, log information like your search queries, IP address, crashes,
          date and time, browser web storage, application data caches, Cookies
          and similar technologies and data of the such Customers (collectively
          and individually referred as, “Customer Data”).
        </p>
        <p className="mb-0">
          The aforesaid Customer Data may be shared with Megafin tahrough
          variety of sources, including through: • Offline interactions with
          Megafin at Point of Sales, marketing campaigns, etc.
        </p>
        <p className="mb-0">
          • Online and electronic interactions viz. Megafin website, mobile
          applications, text messaging or third party applications/ social
          networks, etc.
        </p>
        <p className="mb-0">
          • Your interaction with online targeted content (such as
          advertisements) that Megafin, or its service providers on behalf of
          Megafin, provide to you via third party websites or applications.
        </p>
        <h5 className="fw-bold my-4">Consent of the Customers</h5>
        <p>
          When the Customer applies for any loan or service, we first obtain the
          authority/ consent (including electronic consent viz. e-signatures, ‘I
          agree’ tick checkbox, onetime password, Yes/No authentication) from
          such Customer for collection, storage, analysis, publishing and
          dissemination, at any time of the Customer Data.
        </p>
        <p>
          By providing the Customer Data to Megafin, the Customer (or the
          user) gives his/her express consent to the fact that his/her Customer
          Data will be maintained and processed by us for various purposes,
          including allowing Megafin or its authorized representatives to
          authenticate Customer Data through Unique Identification Authority of
          India, to contact the Customer through phone calls, SMS, or e-mails or
          at postal address, to make follow up calls in relation to the products
          and services provided by or through Megafin and / or its business
          partners or service providers, for imparting product knowledge,
          offering promotional offers & various other offers offered by its
          partners. The Customer has the right to access their Customer Data and
          to have it corrected, including withdrawal of such consent, by
          approaching Megafin While using this Website, you agree not to, by
          any means (including hacking, cracking or defacing any portion of the
          Website) indulge in illegal or unauthorized activities.
        </p>
        <h5 className="my-4 fw-bold">Use and Disclosure of Customer Data</h5>
        <p>
          We protect Customer Data against unauthorized use in the same manner
          in which we protect our confidential information. However, in
          following conditions, we may share Customer Data:
        </p>
        <p className="mb-0">
          • Verification of your credit and financial position, reference
          checks, authentication of personal, biometric and demographic
          information/ data provided to us at the time of loan application or
          anytime thereafter and making related enquiries through references on
          the basis of the data and information provided to, or collected by,
          Megafin as and when we consider necessary;
        </p>
        <p className="mb-0">
          • Sharing, obtaining and/ or disclosing any aspect of your personal,
          biometric, demographic, business, credit and financial information/
          data to any credit bureau, financial institution, Reserve Bank of
          India, any other authority or any third party including services
          providers engaged by Megafin for purpose of proper verification and
          assessment of the Customer Data, for statistics regarding Customer’s
          Account, to satisfy any legally enforceable process or compliance
          purpose, detecting and protecting against error, fraud or
          identification of other criminal activity or for archiving Customer
          Data, for providing or for assisting Megafin in providing loans and
          services to Customers and for complying with the laws applicable from
          time to time.
        </p>
        <p className="mb-0">
          • Use of your information to improve services to the Customers and to
          keep Customers updated about new products or other information that
          may be of interest to such Customers. We may share some of the
          Customer Data to third parties with your prior consent who may
          approach/ contact you to provide attractive offers to you by way of
          advertisements campaigns, information circulation etc.
        </p>
        <p className="mb-0">
          • We also do not disclose images of our customers in testimonials or
          on any media platforms. All images used are for representation purpose
          and owns the copyright of the company
        </p>
        <p className="mb-0">
          • It may become necessary for Megafin to disclose the Customer Data
          to the agents, other service providers and contractors (which includes
          but not limited to KYC repository services, data storage agencies,
          marketing and collection agents, any assigns to whom Megafin intend
          to grant any right or obligation of Megafin) in the course of normal
          business operations for the above referred purpose.
        </p>
        <p className="mb-0">
          • Sharing of information with manufacturers to provide Interest
          Subsidy to customers availing loans/ finance from Megafin for
          purchase of products either at no or lower rate of interest.
        </p>
        <p>
          • We may also invite visitors to this Website to participate in market
          research and surveys and other similar activities. Megafin will use
          the customer information to improve the visitor experience on the
          Website and make subsequent offers to the visitor on products which
          may be of interest to him / her.
        </p>
        <p>
          However, these parties would be required to use the information
          obtained from Megafin either for the purposes mutually agreed with
          Megafin or for the purposes expressly permitted by the applicable
          laws exclusively. Megafin will endeavor to take all reasonable steps
          to ensure that the confidentiality of the Customer Data is maintained
          by imposing strict confidentiality standards on all the private and
          non-statutory third parties to whom it discloses such information.
          Further, Megafin shall not retain the information for longer than is
          required for the purposes for which the information may lawfully be
          used or is otherwise required under any other law for the time being
          in force.
        </p>
        <p>
          Megafin is strongly committed to protecting the privacy of its
          customers and has taken all necessary and reasonable measures that
          commensurate with the information assets being protected to ensure
          confidentiality of the Customer Data and its transmission through the
          world wide web and it shall not be held liable for disclosure of the
          confidential information when in accordance with this policy or in
          terms of the agreements, if any, with the Customers. In addition, we
          use small bits of data called “cookies” stored on user’s computers to
          stimulate a continuous connection. “Cookies” let us store information
          about your preferences and passwords and allow you to move to
          different pages of our Website without having to re-enter your
          password information. Any information collected is stored in secure
          databases protected via a variety of access controls and is treated as
          confidential information by us. Therefore, you should be careful with
          usage of the username and password by maintaining confidentiality and
          ensure that you do not knowingly or accidentally share, provide and
          facilitate unauthorized use of it.
        </p>
        <h5 className="my-4 fw-bold">Exclusions from Privacy Policy</h5>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information. This does not include trusted
          third parties who assist us in operating our Website, conducting our
          business, or servicing you, so long as those parties agree to keep
          this information confidential. Other websites that you may access via
          Website may have different privacy policies and access to such web
          sites will not be subject to this Privacy Policy. We recommend that
          you read the privacy statement of each such website to find out how
          they protect your Customer Data. The exclusions herein shall not
          restrict us from publishing business reports or sector reports of
          behavioral pattern of the customers.
        </p>
        <h5 className="my-4 fw-bold">Amendments</h5>
        <p>
          Due to changes in legislation or enhancements to functionality and
          content on the Website, we may make changes to privacy policy (without
          being obliged to do so) and would reflect those changes in this
          privacy policy. Hence you are requested to go through the privacy
          policy statement on a regular basis.
        </p>
        <h5 className="my-4 fw-bold">Contact Person</h5>
        <p>
          For any query or complaint regarding confidentiality and sharing of
          your Customer Data, including sharing with third parties, or
          suggestion for improvement of this policy, you may contact Grievance
          Redressal Officer, Customer Care Department Megafin, P-810 Block N,
          Tollygunge Circular Road, Opposite New Alipore Telephone Exchange
          Road, New Alipore, Kolkata, West Bengal 700053.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
