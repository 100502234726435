// import React from "react";
// import img1 from "../../images/testimonial1.png";
// import { Carousel } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const Testimonial = () => {
//   return (
//     <>
//       <h2 className="fw-900 mt-5 text-center text-blue">
//         What Client Says About Us
//       </h2>
//       <div className="card border-0 col-md-7 mx-auto mt-5 mb-5 my-0">
//         <Carousel interval={100000}>
//           <Carousel.Item>
//             <div className="carousel-caption">
//               <div className="d-flex flex-wrap CrouselFlex">
//                 <div className="col-sm-3">
//                   <img src={img1} alt="" className="rounded-circle img-fluid" />
//                 </div>
//                 <div
//                   className="col-sm-9"
//                   style={{ borderLeft: "2px solid gray" }}
//                 >
//                   <small>
//                     sit amet, consectetur adipiscing elit. Nulla convallis
//                     libero eget ligula feugiat, sit amet ullamcorper mi
//                     commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.
//                   </small>
//                   <h6 className="smallest mute fw-bold mt-2">James Williams</h6>
//                 </div>
//               </div>
//             </div>
//           </Carousel.Item>
//           <Carousel.Item>
//             <div className="carousel-caption">
//               <div className="d-flex flex-wrap CrouselFlex">
//                 <div className="col-sm-3">
//                   <img src={img1} alt="" className="rounded-circle img-fluid" />
//                 </div>
//                 <div
//                   className="col-sm-9"
//                   style={{ borderLeft: "2px solid gray" }}
//                 >
//                   <small>
//                     sit amet, consectetur adipiscing elit. Nulla convallis
//                     libero eget ligula feugiat, sit amet ullamcorper mi
//                     commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.
//                   </small>
//                   <h6 className="smallest mute fw-bold mt-2">James Williams</h6>
//                 </div>
//               </div>
//             </div>
//           </Carousel.Item>
//           <Carousel.Item>
//             <div className="carousel-caption">
//               <div className="d-flex flex-wrap CrouselFlex">
//                 <div className="col-sm-3">
//                   <img src={img1} alt="" className="rounded-circle img-fluid" />
//                 </div>
//                 <div
//                   className="col-sm-9"
//                   style={{ borderLeft: "2px solid gray" }}
//                 >
//                   <small>
//                     sit amet, consectetur adipiscing elit. Nulla convallis
//                     libero eget ligula feugiat, sit amet ullamcorper mi
//                     commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.sit amet, consectetur adipiscing elit. Nulla
//                     convallis libero eget ligula feugiat, sit amet ullamcorper
//                     mi commodo.
//                   </small>
//                   <h6 className="smallest mute fw-bold mt-2">James Williams</h6>
//                 </div>
//               </div>
//             </div>
//           </Carousel.Item>
//         </Carousel>
//       </div>
//     </>
//   );
// };

// export default Testimonial;

import React from "react";
import img1 from "../../images/testmale.jpeg";
import img2 from "../../images/testimonial1.png";
import t1 from "../../images/t1 (1).jpg";
import t2 from "../../images/t1 (2).jpg";
import t3 from "../../images/t1 (3).jpg";
import t4 from "../../images/t1 (4).jpg";

import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Testimonial = () => {
  return (
    <>
      <h2 className="fw-900 mt-5 text-center text-blue" id="testimonial">
        What Client Says About Us
      </h2>
      <div className="card border-0 col-md-7 mx-auto mt-5 mb-5 my-0">
        <Carousel interval={100000}>
          <Carousel.Item>
            <div className="carousel-caption">
              <div className="d-flex flex-wrap align-items-center  CrouselFlex">
                <div className="col-sm-4">

                  <img src={t1} alt="" 
                    style={{ width: "200px" , height: "200px" }}
                    className=" rounded-circle img-fluid" />
                  {/* <img src={img1} alt="" className="rounded-circle img-fluid" /> */}
                </div>
                <div
                  className="col-sm-8"
                  style={{ borderLeft: "2px solid gray" }}
                >
                  <small>
                    I was amazed at how fast MegaFin approved my loan. The online process is a game-changer!
                  </small>
                  <h6 className="smallest mute fw-bold mt-2">Rajesh Sharma</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>


          <Carousel.Item>
            <div className="carousel-caption">
              <div className="d-flex flex-wrap align-items-center  CrouselFlex">
                <div className="col-sm-4">

                  <img src={t2} alt=""
                    style={{ width: "200px", height: "200px" }}
                    className=" rounded-circle  img-fluid" />
                  {/* <img src={img1} alt="" className="rounded-circle img-fluid" /> */}
                </div>
                <div
                  className="col-sm-8"
                  style={{ borderLeft: "2px solid gray" }}
                >
                  <small>
                    Transparent, reliable, and efficient – MegaFin exceeded my expectations
                  </small>
                  <h6 className="smallest mute fw-bold mt-2">Vikram Gupta</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>


          <Carousel.Item>
            <div className="carousel-caption">
              <div className="d-flex flex-wrap align-items-center  CrouselFlex">
                <div className="col-sm-4">

                  <img src={t3} alt=""
                    style={{ width: "200px", height: "200px" }}
                    className=" rounded-circle img-fluid" />
                  {/* <img src={img1} alt="" className="rounded-circle img-fluid" /> */}
                </div>
                <div
                  className="col-sm-8"
                  style={{ borderLeft: "2px solid gray" }}
                >
                  <small>
                    Securing a home loan seemed daunting until I found MegaFin. Their supportive team guided me through every step, making the process surprisingly easy. I'm now a proud homeowner, thanks to MegaFin's reliable services.
                  </small>
                  <h6 className="smallest mute fw-bold mt-2">Amit Patel</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>





          <Carousel.Item>
            <div className="carousel-caption">
              <div className="d-flex flex-wrap align-items-center CrouselFlex">
                <div className="col-sm-3">
                
                  <img src="https://media.licdn.com/dms/image/D4D03AQFpDHG2RbcfcA/profile-displayphoto-shrink_800_800/0/1693991236574?e=1712793600&v=beta&t=dW62cqO6IAfNkzzB1pAnNqUFJ1UqDBGu2sGYaIBxS7I" alt="" className="rounded-circle img-fluid" />
                  {/* <img src={img1} alt="" className="rounded-circle img-fluid" /> */}
                </div>
                <div
                  className="col-sm-9"
                  style={{ borderLeft: "2px solid gray" }}
                >
                  <small>
                  Ready to embark on your financial journey with MegaFin? Start your application today and discover a world of easy, transparent, and efficient online loans. Your dreams are just a click away!
                  </small>
                  <h6 className="smallest mute fw-bold mt-2">Sanjay Kumar</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-caption">
              <div className="d-flex flex-wrap align-items-center CrouselFlex">
                <div className="col-sm-3">
                  {/* <img src={img2} alt="" className="rounded-circle img-fluid" /> */}
                  <img src="https://media.licdn.com/dms/image/D4D03AQFhXRJpYzDmWg/profile-displayphoto-shrink_800_800/0/1690464125245?e=1712793600&v=beta&t=YDVrkD50UcAuoEZWj10rdTp4a1BJBmLdBh93WJ3Dego" alt="" className="rounded-circle img-fluid" />
                </div>
                <div
                  className="col-sm-9"
                  style={{ borderLeft: "2px solid gray" }}
                >
                  <small>
                  Our success is measured by the satisfaction of our clients. Join the growing community of individuals who have experienced the MegaFin difference.
                  </small>
                  <h6 className="smallest mute fw-bold mt-2">
                    Sheetal
                  </h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-caption">
              <div className="d-flex flex-wrap align-items-center CrouselFlex">
                <div className="col-sm-3">
                  <img src={t4}
                    style={{ width: "200px", height: "200px" }}
                   alt="" className="rounded-circle img-fluid" />
                </div>
                <div
                  className="col-sm-9"
                  style={{ borderLeft: "2px solid gray" }}
                >
                  <small>
                    MegaFin made the loan process so simple and quick. I got the funds I needed without any hassle!
                  </small>
                  <h6 className="smallest mute fw-bold mt-2">Sanjay Mehta</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </>
  );
};

export default Testimonial;
