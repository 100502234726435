import React from "react";
import img1 from "../../images/about.png";

const Aboutus = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row align-items-center">
          <div className="col-md-6 mt-5">
            <h3 className="fw-bolder text-blue">About Us</h3>
            <p className="fs-14 fw-500">
              At MegaFin, we pride ourselves on being a reliable and customer-centric online lending platform. Our mission is simple – to provide you with quick and accessible financial solutions tailored to your needs. With a commitment to transparency and customer satisfaction, we strive to redefine the way you experience loans.
            </p>
            <p className="fs-14 fw-500">
              {" "}
              MegaFin is a team of financial experts dedicated to simplifying the loan application process. We leverage cutting-edge technology to offer you a 100% online experience, making financial assistance just a click away. Our user-friendly platform ensures that you can navigate through the loan application with ease, even if it's your first time. MegaFin is not just a loan provider; we are your financial partner on the journey to realizing your dreams. With a mission to make the loan process simple and accessible to everyone, we have curated a platform that puts your needs first.
            </p>
          </div>
          <div className="col-md-6">
            <img src={img1} className="w-100" alt="" />
          </div>
        </div>
        <p className="fs-14 fw-500 mt-4">
          Applying for a loan with MegaFin is a breeze! Just follow these simple steps: <br />
          Visit Our Website: Navigate to our user-friendly website, MegaFin.com. <br />
          Create an Account: Sign up and create your account in a few easy steps. <br />
          Fill in Your Details: Provide the necessary information to help us understand your financial needs. <br />
          Choose Your Loan: Select the loan amount that suits your requirements. <br />
          Upload Documents: Experience the ease of our minimum documentation process. <br />
          Submit Your Application: Hit the submit button, and you're done!
        </p>
        {/* <p className="fs-14 fw-500">
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
          dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est
          laborum."Excepteur sint occaecat cupidatat non proident, sunt in culpa
          qui officia deserunt mollit anim id est
        </p> */}
      </div>
    </>
  );
};

export default Aboutus;
