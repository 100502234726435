import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Terms = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <button className="fw-bold my-4 mx-auto container d-flex 
        justify-content-center btn bg-orange text-light col-md-4">
          Terms and Conditions
        </button>
        <h5 className="fw-bold my-4">Nomenclature</h5>
        <p>
          All loans are given in the form of demand loans. It is either the
          Customer who contacts the Company or our representatives who contact
          the Customer on the basis of information provided to the Company by
          the Customers themselves using our Website.
        </p>
        <h5 className="fw-bold my-4">Tenure of the Loan</h5>
        <p>
          All the minimum tenures are approved for{" "}
          <b> 1 Years and a maximum period of 20 Years. </b> Once the loan is
          taken, the tenure of the loan cannot be extended under any
          circumstances.
        </p>
        <h5 className="fw-bold my-4">Eligibility for the Loan</h5>
        <p className="mb-0">
          Any individual who is a citizen of India and fulfills all requirements
          for the loan and KYC norms as per RBI guidelines is eligible to apply
          for a loan. The basic documents that are required for every loan are:
        </p>
        <ul>
          <li> UIDAI No(Aadhaar Card)</li>
          <li>Bank Account Number</li>
        </ul>
        <h5 className="fw-bold my-4">Purposes</h5>
        <p>
          A personal loan is a convenient financing option for consolidating
          existing debts. Among the most useful personal loan reasons, debt
          consolidation is where you use the money to pay off multiple loans at
          once. Loans shall not be availed for any speculative, illegal, or
          unlawful purposes that violate the laws of the Country.
        </p>
        <h5 className="fw-bold my-4">Loan Amount</h5>
        <p>
          We offer our users with the flexibility to avail a minimum loan amount
          of <b> ₹ 10 Thousand /- and a maximum loan amount of ₹ 60 Lakh/- </b>{" "}
        </p>
        <h5 className="fw-bold my-4">Loan Approval</h5>
        <p>
          After considering all the facts and aspects of the loan application,
          the applicant will be intimated in due course of time regarding
          approval of the loan by the Megafin. The loan will be approved after
          thoroughly checking your documents, etc. The Megafin provides
          financial assistance to the customers on its own, and the Company has
          tie-ups with several banks. The Company is authorized to offer you
          loans through these banks as well.
        </p>
        <h5 className="fw-bold my-4">Interest and charges</h5>
        <p className="mb-0">
          Interest rates depend on the type of loan availed by the customer. The
          interest rate ranges between 7.00% to 24.00% annually. The interest
          shall be calculated for the actual number of days the loan remains
          outstanding, it refers to the period between the date of the loan
          disbursement and the date of closure.
        </p>
        <p>
          A grace period up to 3 days on the due date may be allowed to the
          client at the sole discretion of the Company. For the purpose of
          calculation of interest, a year will be counted as 365 days. Interest
          is calculated on 30 days compounding basis.
        </p>
        <h5 className="fw-bold my-4">Penal interest</h5>
        <p>
          If the installments are not received as per the repayment terms, by
          the end of the month, the borrower will be charged interest on the
          installments delayed, penal interest as decided by the Company shall
          be charged over and above the regular interest prospectively.
        </p>
        <h5 className="fw-bold my-4">Loan Repayment</h5>
        <p>
          The customer has to pay the EMI every month till the loan tenure is
          over and the entire loan is cleared. It is the duty of the customer to
          pay the EMI on time as it not only saves late fee charges but it also
          improves the CIBIL score of the customer. So a late fee interest of
          1.5% will be charged on the EMI amount on a daily basis.
        </p>
        <h5 className="fw-bold my-4">Fraud Prevention</h5>
        <p className="mb-0">
          All kinds of fraudulent activities or attempts to defraud, whether it
          is by our employees or outsiders, must be brought to the knowledge of
          the Management as soon as it is detected so that we can take
          appropriate action against the miscreant.
        </p>
        <p className="mb-0">Employees of Central/State Government</p>
        <p className="mb-0">
          A loan may be given to any person working in State/ Central Government
          within India.
        </p>
        <h5 className="fw-bold my-4">Relaxation on the Interest</h5>
        <p>
          If the full and final payment of the loan amount is made before the
          stipulated period, the applicant can get a waiver of interest.
        </p>
        <h5 className="fw-bold my-4">The Company May Demand</h5>
        <p className="mb-0">
          There are chances that the Company may demand the following elements
          in order to complete the procedure of the loan. The Company will
          choose whether to demand these from you or not based on the following
          three criteria:
        </p>
        <ul>
          <li> The type of loan you have availed</li>
          <li>The amount of loan you have demanded</li>
          <li>Your Locality like city-area</li>
        </ul>
        <h5 className="fw-bold my-4">Refund Policy/ Non-Refundable Charges</h5>
        <p>
          If the customer fails to complete the loan process due to any reasons
          or problems, the service charges and other charges levied in the
          process will be non-refundable.
        </p>
        <h5 className="fw-bold my-4">Cancellation Policy</h5>
        <p>
          If the customer wants to cancel his/her application he can do so
          before the loan preocess anytime within 3 days, the service charges
          and other charges levied in the process will be non-refundable.
        </p>
        <h5 className="fw-bold my-4">Suspension of Services</h5>
        <p>
          The Company may also suspend services on the web or cancel loan
          requests for any customer at its sole discretion without assigning any
          reason, whatsoever.
        </p>
        <h5 className="fw-bold my-4">
          If Any Party Fails to Complete the Process
        </h5>
        <p className="mb-0">
          If the customer is unable to complete the process, the following will
          be applicable:
        </p>
        <p className="mb-0">
          1. The Company will not refund any amount in such cases. The Company
          is not responsible for or liable for, any action, claims, demands,
          losses, damages, costs, charges, and expenses that a customer may
          suffer, sustain or incur due to the offer.
        </p>
        <p>
          2. If the Company is unable to disburse the loan amount due to
          mismatch of the details in UIADI document or bank account number or
          any other related documents then the loan seeker/customer may not make
          any issues or from the legal aspects because he/she could not applied
          with genuine documents or details to the company.
        </p>
        <h5 className="fw-bold my-4">Loan Disbursal</h5>
        <p>
          Once the applicant submits all the documents and completes all the
          formalities as per the requirement of the company, the finance company
          will repay the loan through online payment within one working day.
        </p>
        <h5 className="fw-bold my-4">Transaction Issues</h5>
        <p>
          Returned, Disputed or Un-Authorized / fraudulent transactions by the
          customers will not be considered.
        </p>
        <h5 className="fw-bold my-4">Recovery Agents</h5>
        <p>
          The company has a dedicated recovery department that takes care of the
          recovery of the loan amount. There are several authorized recovery
          agents in this department who are responsible for recovery of money in
          case the customer fails to pay the EMI. In case a customer does not
          pay the EMIs on time, these recovery agents will try to recover the
          loan amount in a manner that they deem appropriate. These agents may
          call you or contact you at the address provided. They have their own
          way of dealing with defaulting clients and the company has no
          interference in it.
        </p>
        <h5 className="fw-bold my-4">Disputes</h5>
        <p>
          If the customer holds any complaint against the company for any
          reason, the customer should not, in any case, protest the forum,
          damage the property of the company, fight, misbehave, etc., then legal
          action against the company entitled to take action. customer.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
