import React from "react";
import logo from "../images/footer_logo.png";
import { FaInstagram, FaSquareFacebook } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <div className="bg-blue d-flex flex-column align-items-center justify-content-center pt-5 pb-3">
        <img src={logo} width={270} alt="" />
        <ul class="d-flex secondFooterRow gap-4 mb-2 mb-lg-0 mt-3">
          <li class="nav-item footer_link">
            <a
              class="nav-link text-light fw-normal text-center"
              aria-current="page"
              href="#"
            >
             Address: Dohariya, Bariyapur Road, Dohariya Bazar, Gorakhpur, Utter Pradesh, 273015
            </a>
          </li>
        </ul>

        <div className="d-flex flex-md-row flex-column gap-4 align-md-items-start justify-content-center mt-3">
          <div className="nav-link text-light fw-normal text-center ">
            <h5 className="">
              Email
            </h5>
            <span className="">
              Help@magafin.net
            </span>
          </div>



          <div className="nav-link text-light fw-normal text-center ">
            <h5 className="">
              Contact Us:
            </h5>
            <span className="">
              +91  8420375031
            </span>
          </div>


          <div className="nav-link text-light fw-normal text-center ">
            <h5 className="">
              Contact with Us:
            </h5>
            <div className="fs-3 d-flex align-items-center gap-3 justify-content-center">
              <FaSquareFacebook className="text-primary" />
              <FaInstagram  className="text-primary" />
            </div>
          </div>

        </div>
      
 
        <div className="footer_main d-flex flex-column">

          <h3 className="fw-bold text-light text-center py-2 mt-3">Our Company</h3>

          <ul class="d-flex  firstFooterRo justify-content-center flex-wrap  gap-4 mb-2 mb-lg-0 mt-3">
            <li class="nav-item footer_link">
              <a
                class="nav-link text-light fw-normal"
                aria-current="page"
                href="/"
              >
                Home
              </a>
            </li>
            <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="/aboutus">
                About Us
              </a>
            </li>
            <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="/contactus">
                Contact Us
              </a>
            </li>

            <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="/polcey">
                MegaFin Policy
              </a>
            </li>

            <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="#testimonial">
                MegaFin Happy Customer
              </a>
            </li>
            {/* <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="#">
                My Account
              </a>
            </li> */}
            {/* <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="#">
                DSA Login
              </a>
            </li> */}
          </ul>
          <ul class="d-flex secondFooterRow gap-4 mb-2 mb-lg-0 mt-3">
            {/* <li class="nav-item footer_link">
              <a
                class="nav-link text-light fw-normal"
                aria-current="page"
                href="#"
              >
                Terms and conditions
              </a>
            </li> */}
        

            {/* <li class="nav-item footer_link">
              <a class="nav-link text-light fw-normal" href="#">
                Phone: +91 8018966740
              </a>
            </li> */}
          </ul>
        </div>
<p className="text-center py-3 text-white">Copyright ©️ 2024 MegaFin Is a Product/Unit Of Siya Traders.</p>
      </div>
    </>
  );
};

export default Footer;
