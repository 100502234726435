import React, { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import {
  getUsersDetail
} from "../../services/dashboard";

const Customer = () => {
  const navigate = useNavigate();
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [whatsappData, setWhatsappData] = useState([]);

  const handleCheck = ( event ) => {
    event.preventDefault();

    if (!whatsappNumber.trim()) {
      alert('Please enter a valid Unique ID.');
      return;
    }

    if (!whatsappData || whatsappData.length === 0) {
      alert('No data available. Please try again later.');
      return;
    }

    const filteredData = whatsappData.filter((data) => data !== null);

    const isWhatsappNumberExist = filteredData.some(
      (data) => data === whatsappNumber
    );

    if (isWhatsappNumberExist) {
      navigate(`/dashboard?whatsappNumber=${whatsappNumber}`);
    } else {
      alert('WhatsApp number not found.');
    }
  };


  useEffect(() => {
    getUsersDetail()
      .then((res) => {
      const whatsappNumbersArray = res.map((data) => data?.userDetail?.whatsappNumber);
      setWhatsappData(whatsappNumbersArray.filter(Boolean));
      console.log(whatsappNumbersArray, "currentData");
      })
      .catch((error) => {
        setWhatsappData({});
      });
  }, []);

  return (
    <>
      <div className="adminLogin_img">
        <div
          className="admin_Form col-md-4 mx-4 py-5 px-5"
          style={{
            border: "1px solid rgba(191, 218, 198, 0.6)",
            borderRadius: "1rem",
          }}
        >
          <h3 className="fw-bold text-center">Check Customer User Person</h3>
          <p className="mb-4 text-center">
            By Mobile/ Aaadhar / Pan / Email Address
          </p>
          <form>
            <div class="mb-1">
              <input
                type="text"
                onChange={e => setWhatsappNumber(e.target.value)}
                placeholder="Unique ID"
                class="form-control text-light whiteplaceholder bg-transparent border-secondary shadow-none"
                style={{
                  background:
                    "linear-gradient(rgba(153, 124, 124, 0.17),rgba(153, 124, 124, 0.17))",
                  padding: "10px",
                }}
              />
            </div>

            <button onClick={handleCheck} className="btn mb-3 shadow-none w-100 bg-orange py-2 mt-4 text-light px-4">
              Check
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Customer;
