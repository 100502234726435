import React from "react";
import img1 from "../../images/about.png";

const About = () => {
  return (
    <>
      <div className="container my-5">
        <div className="row align-items-center">
          <div className="col-md-6 my-5">
            <img src={img1} style={{ width: "100%" }} alt="" />
          </div>
          <div className="col-md-6 my-5">
            <h3 className="fw-bold">About MegaFin</h3>
            <p className="fs-15">
            At MegaFin, we pride ourselves on being a reliable and customer-centric online lending platform. Our mission is simple – to provide you with quick and accessible financial solutions tailored to your needs. With a commitment to transparency and customer satisfaction, we strive to redefine the way you experience loans.
            </p>
            <h5 className="fw-bold">Who We Are</h5>
            <p className="fs-15">
            MegaFin is a team of financial experts dedicated to simplifying the loan application process. We leverage cutting-edge technology to offer you a 100% online experience, making financial assistance just a click away. Our user-friendly platform ensures that you can navigate through the loan application with ease, even if it's your first time.MegaFin is not just a loan provider; we are your financial partner on the journey to realizing your dreams. With a mission to make the loan process simple and accessible to everyone, we have curated a platform that puts your needs first.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
